import styled from 'styled-components';

export const ComponentWrapper = styled.nav`
	align-items: center;
	background-color: #E5E5E5;
	color: #494949;
	display: flex;
	height: 7.5rem;
	position: relative;
		@media (max-width: 800px) {
			justify-content: center;
		}
	img {
		margin: 0 1.5rem 0 3rem;
	}
	a {
		color: #494949;
		font-size: 1.8rem;
		font-weight: 600;
		margin: 0 1.5rem
		text-decoration: none;
		text-transform: uppercase;
		transition: color 0.15s ease-in;
		&:hover {
			color: #FFB400;
		}
	}
	.support {
		margin: 0 2rem 0 auto;
	}
	.links-wrapper {
		align-items: center;
		display: none;
		width: 100%;
		z-index: 2;
		@media (max-width: 800px) {
			background-color: #68001B;
			flex-direction: column;
			min-height: 30rem;
			padding: 2rem;
			position: absolute;
			top: 7.5rem;
			transform: translateX(-100%);
			transition: transform 0.15s ease-in;
			width: auto;
			a {
				font-weight: 800;
				line-height: 2;
			}
			&.open {
				transform: translateX(0);
			}
		}

	}
	.social-links {
		display: flex;
		margin-left: auto;
		padding-right: 1.5rem;
		a {
			height: 3rem;
			margin: 0.4rem;
			width: 3rem;
			&.facebook {
				background-image: url('https://webcdn.hirezstudios.com/rogue-company/static/first-watch/FBIcon.png');
				background-size: contain;
			}
			&.twitter {
				background-image: url('https://webcdn.hirezstudios.com/rogue-company/static/first-watch/Twitter.png');
				background-size: contain;
			}
			&.instagram {
				background-image: url('https://webcdn.hirezstudios.com/rogue-company/static/first-watch/Instagram.png');
				background-size: contain;
			}
		}
		@media (max-width: 800px) {
			margin-top: auto;
		}
	}
  .icon-menu {
    cursor: pointer;
    display: none;
    height: 5.6rem;
    position: relative;
    padding: 0 1.2rem 0 1.2rem;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    width: 2rem;
		@media (max-width: 800px) {
			// display: block;
		}
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
    span {
      display: block;
      position: absolute;
      height: .2rem;
      width: 2rem;
      background: white;
      border-radius: 9px;
      opacity: 1;
      left: 1.1rem;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 1.9rem;
      width: 1.4rem;
      left: 1.7rem;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 2.7rem;
    }

    span:nth-child(4) {
      top: 3.5rem;
    }

    &.open span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0%;
    }

    &.open span:nth-child(2) {
      transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &.open span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0%;
    }
  }

`;