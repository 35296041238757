import React, { Component } from 'react';
import { Link } from 'gatsby';
import { ComponentWrapper } from './SiteNavigation.css';

class SiteNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  toggleMenu = () => {
    if (this.state.menuOpen === false) {
      this.setState({ menuOpen: true});
    }
    else {
      this.setState({ menuOpen: false});
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <ComponentWrapper id="c-SiteNavigation">
        {/* <div className={`icon-menu ${this.state.menuOpen === true ? 'open' : ''}`}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div> */}
        <Link to="/"><img src="https://webcdn.hirezstudios.com/rogue-company/static/first-watch/NavLogo.png" alt="First Watch Games Logo" /></Link>
        <a className="support" href="https://www.hirezstudios.com/support" target="_blank" rel="noopener noreferrer">Support</a>
        {/* <div className={`links-wrapper ${this.state.menuOpen === true ? 'open' : ''}`}>
        </div> */}
      </ComponentWrapper>
    );
  } 
};

export default SiteNavigation;