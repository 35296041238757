import styled from 'styled-components'

export const ComponentWrapper = styled.div`
  align-items: center;
  background-color: #e5e5e5;
  display: flex;
  color: #300004;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 2rem 2rem 2rem 4rem;
  .text-container {
    margin-left: auto;
    max-width: 50rem;
    @media (max-width: 600px) {
      margin: 1rem auto;
    }
  }
  a {
    color: #494949;
    margin: 0 1rem;
    text-decoration: none;
    &:hover {
      color: #fff;
    }
  }
  .divider {
    background-color: #494949;
    height: 1px;
    margin: 1rem;
    max-width: 40rem;
    width: 100%;
  }
  .ga-film {
    align-items: center;
    color: #494949;
    display: flex;
    gap: 1rem;
    margin-top: 3rem;
    width: 100%;
    &-disclaimer {
      font-size: 1rem;
      /* @media screen and (min-width: 600px) {
        max-width: 33em;
      } */
    }
    img {
      width: 3rem;
      margin-left: 2rem;
      margin-top: -1rem;
    }
  }
  .ip-notice {
    color: #494949;
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 1rem 1rem;
    max-width: 50rem;
  }
  .logo {
    max-width: 15rem;
    @media (max-width: 600px) {
      display: none;
    }
  }
`
